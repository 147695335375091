import { createTheme } from '@mui/material/styles';
//Evora Palette v2
//Evora Purple: #6B72AE
//Dark Blue: #1B223A
//Storm Blue: #38546D
//Sky Blue: #C8DBEE
//Dark Olive: #ABBB7D
//Light Olive: #D1D7B0

//defaultSettings = General Settings, No Colours
//DarkTheme = Colors & Settings
//LightTheme = Colors & Settings

export const LightTheme = createTheme({
  content: {
    flexGrow: 1,
    padding: '0 25px',
  },
  palette: {
    background: {
      default: '#F2F3F4',
      paper: '#FFF',
      table: '#FFF'
    },
    info: {
      main: '#38546D', //Evora Colour #4: Sky Blue
    },
    success: {
      main: '#ABBB7D', //Evora Colour #5: Light Olive
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#6B72AE', //Evora Colour #1: Purple
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: '#0066ff',
      main: '#ABBB7D', //Evora Colour #2: Dark Olive
      // dark: will be calculated from palette.secondary.main,
      // contrastText: '#ffcc00',
    },
    error: {
      // light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
    },
    warning: {
      light: '#FF9800',
      main: '#ed6c02',
      dark: '#e65100',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: [
      'Poppins',
      'Futura PT',
      'Prompt'
    ].join(','),
    h1: {
      color: '#38546D', //Evora Colour #2: Dark Olive
      fontFamily: 'Futura PT',
      fontSize: '2.5rem',
      fontWeight: '500',
      marginBottom: 10
    },
    h2: {

    },
    h3: {
      color: '#ABBB7D', //Evora Colour #2: Dark Olive
      fontFamily: 'Futura PT',
      fontSize: '2.8rem',
      fontWeight: '500',
      marginBottom: 10
    },
    h4: {
      color: '#ABBB7D', //Evora Colour #2: Dark Olive
      fontFamily: 'Futura PT',
      overflow: 'auto', 
      marginTop: '5px', 
      marginLeft: '7px',
      display: 'inline'
    },
    h5: {
      color: '#ABBB7D', //Evora Colour #2: Dark Olive
      fontFamily: 'Futura PT',
      display: 'inline'
    },
    h6: {
      color: '#ABBB7D', //Evora Colour #2: Dark Olive
      fontFamily: 'Futura PT',
      display: 'inline'
    },
    dialogTitle: {
      color: 'primary.main',
      marginTop: '0.5rem',
      fontFamily: 'Poppins',
      fontSize: '1.25em',
      fontWeight: '600', // Use numeric value for fontWeight
    },
    body1: {
      fontSize: '0.9rem',
    },
    body2: {
      fontSize: '0.8rem',
    },
    subtitle2: {
      fontSize: '0.75rem',
    }
  },
  components: {
    MuiAppBar: {
      variants: [
        {
          props: { variant: 'topAppBar' },
          style: {
            backgroundColor: '#1B223A', //Evora Colour #3: Dark Blue
            color: '#C8DBEE', //Evora Colour #4: Sky Blue
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px'
          },
        },
        {
          props: { variant: 'mobileAppBar' },
          style: {
            backgroundColor: '#6B72AE', //Evora Colour #5: Evora Purple
            position: 'fixed',
            top: 'auto',
            bottom: 0,
          },
        },
        {
          props: { variant: 'breadcrumbs' },
          style: {
            backgroundColor: '#CBDBEE', //Evora Colour #4: Sky Blue
            position: 'fixed',
            marginTop: '63px',
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px'
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'closeDialog' },
          style: {
            color: '#6B72AE',
            marginLeft: 8,
            marginRight: 8,
            '&:hover': {
              color: '#ABBB7D',
            }
          }
        },
        {
          props: { variant: 'deleteDialog' },
          style: {
            color: '#f44336',
            marginLeft: 8,
            marginRight: 8,
            '&:hover': {
              backgroundColor: '#6B72AE',
            }
          },
          variant: 'text',
          size: 'small'
        },
        {
          props: { variant: 'combine' },
          style: {
            background: '#1B223A',
            color: '#FFF',
            marginLeft: 8,
            marginRight: 8,
            '&:hover': {
              backgroundColor: '#6B72AE',
            }
          },
          variant: 'contained',
          size: 'small'
        },
        {
          props: { variant: 'copy' },
          style: {
            background: '#ABBB7D',
            color: '#FFF',
            marginLeft: 8,
            marginRight: 8,
            '&:hover': {
              backgroundColor: '#6B72AE',
            }
          },
          variant: 'contained',
          size: 'small'
        },
        {
          props: { variant: 'delete' },
          style: {
            background: '#f44336',
            color: '#FFF',
            marginLeft: 8,
            marginRight: 8,
            '&:hover': {
              backgroundColor: '#6B72AE',
            }
          },
          variant: 'contained',
          small: 'small'
        },
        {
          props: { variant: 'move' },
          style: {
            background: '#1B223A',
            color: '#FFF',
            marginLeft: 8,
            marginRight: 8,
            '&:hover': {
              backgroundColor: '#6B72AE',
            }
          },
          variant: 'contained',
          size: 'small'
        },
        {
          props: { variant: 'print' },
          style: {
            background: '#38546D',
            color: '#FFF',
            marginLeft: 8,
            marginRight: 8,
            '&:hover': {
              backgroundColor: '#6B72AE',
            }
          },
          variant: 'contained',
          size: 'small'
        },
        {
          props: { variant: 'release' },
          style: {
            background: '#ABBB7D',
            color: '#FFF',
            marginLeft: 8,
            marginRight: 8,
            '&:hover': {
              backgroundColor: '#6B72AE',
            }
          },
          variant: 'contained',
          size: 'small'
        },
        {
          props: { variant: 'save' },
          style: {
            background: '#ABBB7D',
            margin: '3px 0 2px 0',
            padding: '6px 16px',
            marginLeft: 8,
            marginRight: 8,
            color: '#FFF',
            '&:hover': {
              backgroundColor: '#6B72AE',
            }
          },
          variant: 'contained',
          size: 'small'
        },
        {
          props: { variant: 'signIn' },
          style: {
            background: 'linear-gradient(45deg, #ABBB7D 30%, #38546D 90%)',
            margin: '3px 0 2px 0',
            width: '55%',
            padding: '6px 16px',
            color: '#FFF'
          }
        },
        {
          props: { variant: 'split' },
          style: {
            background: '#38546D',
            color: '#FFF',
            marginLeft: 8,
            marginRight: 8,
            '&:hover': {
              backgroundColor: '#6B72AE',
            }
          },
          variant: 'contained',
          size: 'small'
        },
        {
          props: { variant: 'unlock' },
          style: {
            background: '#ED6C02',
            color: '#FFF',
            marginLeft: 8,
            marginRight: 8,
            '&:hover': {
              backgroundColor: '#6B72AE',
            }
          },
          variant: 'contained',
          size: 'small'
        }
      ]
    },
    MuiButtonGroup: {
      variants: [
        {
          props: { variant: 'stackedButton' },
          style: {
            background: '#ABBB7D',
            color: '#FFF',
            '&:hover': {
              backgroundColor: '#6B72AE',
            }
          },
          variant: 'contained',
          size: 'small'
        },
      ]
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '0.85rem',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          fontSize: '0.8rem',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "#db3131",
          "&$error": {
            color: "#db3131",
          },
        },
      },
    },
    MuiGridItem: {
      styleOverrides: {
        root: {
          fontSize: '0.8rem',
        },
      },
    },
    MuiIconButton: {
      variants: [
        {
          props: { variant: 'actionBarActive' },
          style: {
            margin: 0,
            minWidth: 0,
            color: '#ABBB7D'
          }
        },
        {
          props: { variant: 'actionBarInactive' },
          style: {
            margin: 0,
            minWidth: 0,
            color: '#e8e8e8'
          }
        }
      ]
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
        fontSize: '0.8rem'         
      },
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: '#6B72AE'
          },
          '&:hover': {
            color: '#38546D'
          },
          '&.active': {
            color: '#ABBB7D'
          },
          textDecoration: 'none', 
        }
      }
    },
    MuiPaper: {
      variants: [
        {
          props: { variant: 'panel' },
          style: {
            // backgroundColor: '#C8DBEE',
            backgroundColor: '#FFF', 
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px'
          },
        },
      ]
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 600,
          borderBottom: '2px solid rgba(224, 224, 224, 1)',
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          marginTop: 'auto'
        },
        displayedRows: {
          marginTop: 'auto'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
            {
              display: 'none',
            },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        },
      },
    }
  }
});

export const DarkTheme = createTheme({
  palette: {
    background: {
      default: '#1B223A',
      paper: '#1B223A',
      table: '#C8DBEE'
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ABBB7D', //Evora Colour #1: Purple
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: '#0066ff',
      main: '#C8DBEE', //Evora Colour #2: Teal
      // dark: will be calculated from palette.secondary.main,
      // contrastText: '#ffcc00',
    },
    // error: {
    //   light: '#e57373',
    //   main: '#f44336',
    //   dark: '#d32f2f',
    // },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: [
      'Poppins',
      'Futura PT',
      'Prompt'
    ].join(','),
    h3: {
      color: '#6B72AE',
    },
    h4: {
      color: '#6B72AE',
    },
    h5: {
      color: '#6B72AE'
    },
    h6: {
      color: '#6B72AE',
    }
  },
  components: {
    MuiAppBar: {
      variants: [
        {
          props: { variant: 'topAppBar' },
          style: {
            backgroundColor: '#D1D7B0',
            color: '#1B223A',
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px'
          },
        },
        {
          props: { variant: 'mobileAppBar' },
          style: {
            backgroundColor: '#6B72AE',
            position: 'fixed',
            top: 'auto',
            bottom: 0,
          },
        },
        {
          props: { variant: 'breadcrumbs' },
          style: {
            backgroundColor: '#C8DBEE',
            position: 'fixed',
            marginTop: '63px',
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px'
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'signIn' },
          style: (theme) => ({
            ...theme.components.MuiButton.variants.find(variant => variant.props.variant === 'contained').style,
            background: 'linear-gradient(45deg, #ABBB7D 30%, #1B223A 90%)',
            margin: '3px 0 2px 0',
            width: '65%',
          }),
        }
      ]
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "#db3131",
          "&$error": {
            color: "#db3131",
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 1,
      },
      variants: [
        {
          props: { variant: 'panel' },
          style: {
            // backgroundColor: '#C8DBEE',
            // backgroundColor: '#303030', //
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px'
          },
        },
      ]
    }
  }
});
