import { createTheme } from '@mui/material/styles';
//Primary: #484A9A
//Secondary: #5BC6CC
//Green: #ABBB7D
//Yellow: #CBDB2F

//Evora Palette v2
//Evora Purple: #6B72AE
//Dark Blue: #1B223A
//Storm Blue: #38546D
//Sky Blue: #C8DBEE
//Dark Olive: #ABBB7D
//Light Olive: #D1D7B0

const theme = createTheme({
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#6B72AE', //Evora Colour #1: Purple
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: '#0066ff',
        main: '#5BC6CC', //Evora Colour #2: Teal
        // dark: will be calculated from palette.secondary.main,
        // contrastText: '#ffcc00',
      },
      // error: {
      //   light: '#e57373',
      //   main: '#f44336',
      //   dark: '#d32f2f',
      // },
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    content: {
      flexGrow: 1,
      padding: '0 25px',
    },
    typography: {
      fontFamily: [
        'Raleway',
        'Prompt'
      ].join(','),
      h3: {
        fontSize: '2.8rem',
        fontWeight: '500',
        color: '#ABBB7D',
        marginBottom: 10
      },
      h4: {
        color: '#ABBB7D',
        overflow: 'auto', 
        marginTop: '5px', 
        marginLeft: '7px',
        display: 'inline'
      },
      h5: {
        color: '#ABBB7D',
        display: 'inline'
      },
      h6: {
        color: '#ABBB7D',
        display: 'inline'
      },
      body1: {
        fontSize: '0.9rem',
      },
      body2: {
        fontSize: '0.8rem',
      }
    },
    components: {
      MuiFormLabel: {
        styleOverrides: {
          fontFamily: 'Raleway',
          asterisk: {
            color: "#db3131",
            "&$error": {
              color: "#db3131",
            },
          },
        },
      },
      MuiLink: {
        defaultProps: {
          underline: 'hover',
          fontSize: '0.8rem'         
        }
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            fontFamily: 'Raleway',
            fontWeight: 600,
            borderBottom: '2px solid rgba(224, 224, 224, 1)',
          }
        }
      },
      MuiTablePagination: {
        styleOverrides: {
          selectLabel: {
            marginTop: 'auto'
          },
          displayedRows: {
            marginTop: 'auto'
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
              {
                display: 'none',
              },
            '& input[type=number]': {
              MozAppearance: 'textfield',
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: '0.85rem',
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            fontSize: '0.8rem',
          },
        },
      },
      MuiGridItem: {
        styleOverrides: {
          root: {
            fontSize: '0.8rem',
          },
        },
      }
    }
});

export default theme;