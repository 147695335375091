import React from 'react';
import Panel from '../Panel/Panel';
import { styled } from '@mui/material/styles';
import { AppBar, Button, Paper, Tabs, Tab } from '@mui/material';

const StyledTabs = styled((props) => <Tabs {...props} />)((({ theme }) => ({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.primary.main,
  },
  '& .MuiTabs-scrollButtons.Mui-disabled': {
    opacity: 0.3
  }
})));

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: 'rgba(0, 0, 0, 0.85)',
  fontFamily: [
    'Poppins',
  ].join(','),
  '&:hover': {
    color: theme.palette.secondary.main,
    opacity: 1,
  },
  '&.Mui-selected': {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));

const VerticalTabs = styled((props) => <Tabs {...props} />)((({ theme }) => ({
  borderRight: `1px solid #e8e8e8`,
  width: 125,
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.secondary.main,
  }
})));

const VerticalTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: theme.spacing(1),
    fontFamily: [
      'Poppins',
    ].join(','),
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '&.Mui-selected': {
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightBold,
    },
    '&.Mui-focusVisible': {
      color: theme.palette.secondary.main,
    },
  },
  selected: {},
  wrapper: {
    alignItems: 'flex-end',
  },
}));

const ButtonInTabs = ({children, className, disabled, key, onClick }) => {
  return <Button className={className} disabled={disabled} key={key} onClick={onClick} children={children} />;
};

export function EvoraTabs(props) {
  const { tabLabels, tabKey, changeTab } = props;
  return (
    <AppBar position="static" color='transparent' elevation={0} sx={{ mb: 2 }}>
      <StyledTabs 
          value={tabKey} 
          onChange={changeTab} 
          aria-label="navigation-tabs" 
          scrollButtons="auto" 
          variant="scrollable"
      >
        {tabLabels.map((value, index) => {          
          if((value.type != null && value.type !== '') && value.type === 'button'){
            return <ButtonInTabs
                            onClick={value.onClick}
                            key={'tabButton-' + index}
                            disabled={value.disabled}
                          >
                            {value.icon}
                            {value.name}
                        </ButtonInTabs>
          }else {
            return <StyledTab key={index} label={value.name} id={`evora-tab-${index}`} disabled={value.disabled} />
          }
        })}
      </StyledTabs>
    </AppBar>
  );
}

export function EvoraVerticalTabs(props) {
  const { TabContents, tabLabels, tabKey, changeTab } = props;
  return (
      <div style={{ flexGrow: 1, display: 'flex' }}>
          <VerticalTabs 
                value={tabKey} 
                onChange={changeTab} 
                aria-label="vertical-tabs" 
                scrollButtons="auto" 
                variant="scrollable" 
                orientation="vertical"
          >
            {tabLabels.map((value, index) => {
              if((value.type != null && value.type !== '') && value.type === 'button'){
                return <ButtonInTabs
                            onClick={value.onClick}
                            key={'vertTabButton-' + index}
                            disabled={value.disabled}
                          >
                            {value.icon}
                            {value.name}
                        </ButtonInTabs>
              }else {
                return <VerticalTab key={'vertTab-' + value.name + '-' + index} label={value.name} disabled={value.disabled} />
              }              
            })}
          </VerticalTabs>
          <TabContents />
      </div>      
  );
}

export function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index &&
      <Panel>
        {children}
      </Panel>
    }
  </div>
  );
}

export function VerticalTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`vertical-tabpanel-${index}`}
    aria-labelledby={`vertical-tab-${index}`}
    {...other}
    style={{
      width: '85%',
      padding: 10,    
      boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
      borderRadius: '4px',
      color: 'rgba(0, 0, 0, 0.87)',
      transition: 'boxShadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      backgroundColor: '#fff'
    }}
  >
    {value === index ? 
      <Paper elevation={3}>
        {children}
      </Paper> : null}
  </div>
  );
}

export function TabCanvas(props) {
  const { children, value, index, ...other } = props;
  return (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index ? children : null}
  </div>
  );
}

export function VerticalTabCanvas(props) {
  const { children, value, index, ...other } = props;
  return (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`vertical-tabpanel-${index}`}
    aria-labelledby={`vertical-tab-${index}`}
    {...other}
    style={{
      width: '85%',
      padding: 10,    
      boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
      borderRadius: '4px',
      color: 'rgba(0, 0, 0, 0.87)',
      transition: 'boxShadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      backgroundColor: '#fff'
    }}
  >
    {value === index ? children : null}
  </div>
  );
}