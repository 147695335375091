import MuiButton from '@mui/material/Button';
import LockOpen from '@mui/icons-material/LockOpenRounded';
import SaveIcon from '@mui/icons-material/SaveRounded';
import SaveOpenIcon from 'mdi-material-ui/ContentSaveEditOutline';
import SaveCloseIcon from 'mdi-material-ui/ContentSaveMoveOutline';
import UnlockIcon from '@mui/icons-material/VpnKeyRounded';
import DeleteIcon from '@mui/icons-material/DeleteForeverRounded';
import PrintIcon from '@mui/icons-material/PrintRounded';
import ReleaseIcon from 'mdi-material-ui/ClockStart';
import SplitIcon from 'mdi-material-ui/CallSplit';
import CombineIcon from 'mdi-material-ui/CallMerge';
import MoveIcon from 'mdi-material-ui/CartArrowRight';
import CopyIcon from '@mui/icons-material/FileCopyTwoTone';

const Button = ({ children, ...props }) => {
    let startIcon = null;
    let endIcon = null;
    switch(props.variant) {
        case 'combine':
            startIcon = <CombineIcon />;
            break;
        case 'copy':
            startIcon = <CopyIcon />;
            break;
        case 'delete':
            startIcon = <DeleteIcon />;
            break;
        case 'move':
            startIcon = <MoveIcon />;
            break;
        case 'print': 
            startIcon = <PrintIcon />;
            break;
        case 'release':
            startIcon = <ReleaseIcon />;
            break;
        case 'signIn':
            startIcon = <LockOpen />;
            break;
        case 'save':
            if(props.type === 'open')
                startIcon = <SaveOpenIcon />;
            else if(props.type === 'close')
                startIcon = <SaveCloseIcon />;
            else
                startIcon = <SaveIcon />;
            break;
        case 'split': 
            startIcon = <SplitIcon />;
            break;
        case 'unlock':
            startIcon = <UnlockIcon />;
            break;
        default:
            break;
    }
    if(startIcon != null) {
        return (    
            <MuiButton {...props} startIcon={startIcon}>
                {children}
            </MuiButton>
        );
    }else if(endIcon != null) {
        return (    
            <MuiButton {...props} endIcon={endIcon}>
                {children}
            </MuiButton>
        );
    }else {
        return (    
            <MuiButton {...props}>
                {children}
            </MuiButton>
        );
    }    
}

export default Button;