import React from 'react';
import Paper from '@mui/material/Paper';

export default function Panel(props) {  
  return (
      <Paper variant='panel' sx={{ 
        marginBottom: 1, 
        padding: 2, 
        borderRadius: 3.5}} >
        {props.children}
      </Paper>
  )
};