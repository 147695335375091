import React from 'react';
import { useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import Aux from '../../../../hoc/Auxilary/Auxilary';
// import LogoFile from './EVT-100-EvoraTech_Logotype_TealLime_RGB.png';
import LogoFile from './Evora.png';
import LogoFileNM from './EvoraNM.png';

export default function Logo(props) {
  const { alt, height, maxHeight, noTenant, open, width } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const env = useSelector(state => state.auth.user.env);
  const profile = useSelector(state => state.profile);
  const tenant = profile.profile != null ? profile.profile.tenantName : '';
  const tenantLogo = profile.profile != null && profile.profile.logo != null ? profile.profile.logo : null;
  const thumb = tenantLogo != null ? 
     "data:application/octet-stream;base64," + tenantLogo : null;
  return(
    <Aux>
      {!open && <img src={noTenant ? LogoFile : LogoFileNM} alt={alt} height={height} width={width}/>}{!isMobile && !noTenant && env != null && env !== '' ? 
        <Aux>{tenantLogo != null ?
          <img src={thumb} alt={tenant} style={{ maxHeight: maxHeight, paddingTop: '10px' }}/> : 
          <span style={{display: 'inline-block',
                       fontFamily: 'Prompt',
                       fontSize: '45px',
                       lineHeight: '30px'}}>{tenant}</span>}&nbsp;&nbsp;
          <span style={{color: '#D1D7B0',
                        display: 'inline-block',
                        fontFamily: 'Prompt',
                        fontSize: '45px',
                        lineHeight: '30px'}}>{env}</span>
        </Aux> : 
        !isMobile && !noTenant ? tenantLogo != null ?
          <img src={thumb} alt={tenant} style={{ maxHeight: maxHeight, marginTop: '15px' }} /> : 
          <span style={{color: '#C8DBEE',
                        display: 'inline-block',
                        fontFamily: 'Prompt',
                        fontSize: '45px',
                        lineHeight: '30px'}}>{tenant}</span> : ''}
    </Aux>
  );
}