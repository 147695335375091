import React from 'react';
import Aux from '../../../hoc/Auxilary/Auxilary';
import Button from '@mui/material/Button';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import PostAddRoundedIcon from '@mui/icons-material/PostAddRounded';
import BackspaceTwoToneIcon from '@mui/icons-material/BackspaceTwoTone';
import ChangeConfigIcon from 'mdi-material-ui/CogSyncOutline';
import AddIcon from '@mui/icons-material/Add';
import TimerIcon from '@mui/icons-material/Timer';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import VpnKey from '@mui/icons-material/VpnKey';
import UpdateButtonIcon from '@mui/icons-material/CachedRounded';
import LoadButtonIcon from '@mui/icons-material/CloudDownloadRounded';
import EditButtonIcon from '@mui/icons-material/EditTwoTone';
import ShowButtonIcon from '@mui/icons-material/PageviewTwoTone';
import RefreshButtonIcon from '@mui/icons-material/RefreshRounded';
import DeviationIcon from 'mdi-material-ui/DirectionsFork';
import ShipIcon from 'mdi-material-ui/Truck';
import CompleteIcon from '@mui/icons-material/DoneOutlineRounded';
import ReceivedIcon from '@mui/icons-material/CallReceived';
import { useTranslation } from 'react-i18next';
import AddActivityIcon from '@mui/icons-material/Add';
import ExcelIcon from 'mdi-material-ui/MicrosoftExcel';
import UploadIcon from '@mui/icons-material/FileUploadRounded';

export function AddBlankButton(props) {
  const { t } = useTranslation();
  let size = props.size !== undefined ? props.size : "small";
  let color = props.color !== undefined ? props.color : "primary";
  let variant = props.variant !== undefined ? props.variant : "outlined";
  let buttonStyle = props.buttonStyle !== undefined ? props.buttonStyle : { mx: 1 };
  return (
    <Aux>
      <Button
        variant={variant}
        color={color}
        sx={buttonStyle}
        startIcon={<AddIcon />}
        name={t('add')}
        size={size}
        onClick={props.addClick}
        disabled={!props.enabled}
      >{props.buttonName}</Button>
    </Aux>
  );
}


export function CreateButton(props) {
  const { t } = useTranslation();
  let size = props.size !== undefined ? props.size : "small";
  let color = props.color !== undefined ? props.color : "primary";
  let text = props.text !== undefined ? props.text : t('createNew');
  const createClick = (e) => {
    e.preventDefault();
    window.location.href=`${props.location}`;
    };
  let clickCommand = null;
  let classSel = null;

  if(props.createClick !== undefined){
    clickCommand = props.createClick;
    classSel = {
      width: 'max-content',
      mx: 1
    };
  }else {
    clickCommand = createClick;
    classSel = {
      width: 'max-content'
    };
  }

  return (
    <Aux>
      <Button
        variant="contained"
        color={color}
        className={classSel}
        startIcon={<PostAddRoundedIcon />}
        name={t('createNew')}
        onClick={clickCommand}
        disabled={!props.security}
        size={size}
        //fullWidth
      >{text}</Button>
    </Aux>
  );
}

export function ChangeConfigButton(props) {
  const { t } = useTranslation();
  let size = props.size !== undefined ? props.size : "small";
  let color = props.color !== undefined ? props.color : "primary";
  return (
    <Aux>
      <Button
        variant="contained"
        color={color}
        startIcon={<ChangeConfigIcon />}
        name={t('changeConfigButton')}
        onClick={(e) => {
        e.preventDefault();
        window.location.href=`${props.location}`;
        }}
        disabled={!props.security}
        size={size}
      >{t('changeConfig')}</Button>
    </Aux>
  );
}

export function ClearButton(props) {
  const { t } = useTranslation();
  let size = props.size !== undefined ? props.size : "small";
  let color = props.color !== undefined ? props.color : "secondary";
  return (
    <Aux>
      <Button
        variant="contained"
        color={color}
        sx={{ mx: 1 }}
        startIcon={<BackspaceTwoToneIcon />}
        name={t('clear')}
        onClick={props.clearClick}
        disabled={!props.enabled}
        size={size}
      >{t('clear')}</Button>
    </Aux>
  );
}

export function ReturnButton(props) {
  const { t } = useTranslation();
  let size = props.size !== undefined ? props.size : "small";
  let color = props.color !== undefined ? props.color : "secondary";
  return (
    <Aux>
      <Button
        variant="contained"
        color={color}
        sx={{ mx: 1 }}
        startIcon={<KeyboardReturnIcon />}
        onClick={props.returnClick}
        name={t('return')}
        size={size}
      >{t('return')}</Button>
    </Aux>
  );
};

export function StartButton(props) {
  const { t } = useTranslation();
  let size = props.size !== undefined ? props.size : "small";
  let color = props.color !== undefined ? props.color : "primary";
  return (
    <Aux>
      <Button
        variant="contained"
        color={color}
        sx={{ background: 'success.main', mx: 1 }}
        startIcon={<TimerIcon />}
        onClick={props.startClick}
        disabled={!props.startEnabled}
        name={t('startButton')}
        size={size}
        fullWidth
      >{t('start')}</Button>
    </Aux>
  );
};

export function StopButton(props) {
  const { t } = useTranslation();
  let size = props.size !== undefined ? props.size : "small";
  let color = props.color !== undefined ? props.color : "primary";
  return (
    <Aux>
      <Button
        variant="contained"
        color={color}
        sx={{ background: 'info.main', mx: 1 }}
        startIcon={<TimerOffIcon />}
        onClick={props.stopClick}
        disabled={!props.stopEnabled}
        name="completed button"
        size={size}
        fullWidth
      >{t('complete')}</Button>
    </Aux>
  );
};

export function ExportButton(props) {
    const { t } = useTranslation();
    let size = props.size !== undefined ? props.size : "small";
    let color = props.color !== undefined ? props.color : "primary";
    return (
      <Aux>
        <Button
          variant="contained"
          color={color}
          sx={{ mx: 1 }}
          startIcon={<ExcelIcon />}
          name="Export"
          disabled={!props.enabled}
          onClick={(e) => {
            e.preventDefault();
            window.location.href=`${props.exportClick}`;
          }}
          size={size}
        >{t('export')}</Button>
      </Aux>
    );
  };

export function UnlockButton(props){
  const { t } = useTranslation();
  let size = props.size !== undefined ? props.size : "small";
  let color = props.color !== undefined ? props.color : "secondary";
  return (
    <Aux>
      <Button
        variant="contained"
        color={color}
        sx={{ mx: 1 }}
        startIcon={<VpnKey />}
        name="unlock"
        disabled={!props.enabled}
        onClick={props.unlockClick}
        size={size}
      >{t('unlock')}</Button>
    </Aux>
  );
};

export function SimpleUnlockButton(props){
  let text = props.locked || props.locked == null ? 'unlock' : 'lock';
  const { t } = useTranslation();
  let size = props.size !== undefined ? props.size : "small";
  let color = props.color !== undefined ? props.color : "secondary";
  return (
    <Aux>
      <Button
        variant="contained"
        color={color}
        sx={{ mx: 1 }}
        startIcon={<VpnKey />}
        name="unlock"
        disabled={!props.enabled}
        onClick={props.simpleUnlockClick}
        size={size}
      >{t(text)}</Button>
    </Aux>
  );
};

export function UpdateButton(props){
  let size = props.size !== undefined ? props.size : "small";
  let color = props.color !== undefined ? props.color : "secondary";
  let fullWidth = props.fullWidth !== undefined ? props.fullWidth : false;
  return (
    <Aux>
      <Button
        variant="contained"
        color={color}
        startIcon={<UpdateButtonIcon />}
        name={"updateButton" + props.name}
        disabled={!props.enabled}
        onClick={props.updateClick}
        size={size}
        fullWidth={fullWidth}
      >{props.name}</Button>
    </Aux>
  );
}

export function LoadButton(props){
  let size = props.size !== undefined ? props.size : "small";
  let color = props.color !== undefined ? props.color : "primary";
  let fullWidth = props.fullWidth !== undefined ? props.fullWidth : false;
  return (
    <Aux>
      <Button
        variant="contained"
        color={color}
        startIcon={<LoadButtonIcon />}
        name={"loadButton" + props.name}
        disabled={!props.enabled}
        onClick={props.loadClick}
        size={size}
        fullWidth={fullWidth}
      >{props.name}</Button>
    </Aux>
  );
}

export function EditButton(props) {
  let size = props.size !== undefined && props.size != null ? props.size : "small";
  let color = props.color !== undefined ? props.color : "primary";
  return (
    <Aux>
      <Button
        variant="contained"
        color={color}
        //sx={{ margin: (0,0,0,0) }}
        startIcon={<EditButtonIcon />}
        name={"editButton" + props.i}
        size={size}
        onClick={props.editClick}
        disabled={!props.enabled}
      >{props.name}</Button>
    </Aux>
  );
}

export function ShowButton(props) {
  let size = props.size !== undefined ? props.size : "small";
  let color = props.color !== undefined ? props.color : "secondary";
  return (
    <Aux>
      <Button
        variant="outlined"
        color={color}
        sx={{ mx: 1 }}
        startIcon={<ShowButtonIcon />}
        name={"showButton" + props.i}
        size={size}
        onClick={props.showClick}
        disabled={!props.enabled}
      >{props.buttonName}</Button>
    </Aux>
  );
}

export function RefreshButton(props) {
  const { t } = useTranslation();
  let size = props.size !== undefined ? props.size : "small";
  let color = props.color !== undefined ? props.color : "secondary";
  return (
    <Aux>
      <Button
        variant="contained"
        color={color}
        startIcon={<RefreshButtonIcon />}
        name={"refreshButton"}
        size={size}
        onClick={props.refreshClick}
        disabled={!props.enabled}
      >{t('refresh')}</Button>
    </Aux>
  )
}

export function DeviationButton(props) {
    const { t } = useTranslation();
    let size = props.size !== undefined ? props.size : "small";
    let color = props.color !== undefined ? props.color : "secondary";
    return (
      <Aux>
        <Button
          variant="contained"
          color={color}
          sx={{ mx: 1 }}
          startIcon={<DeviationIcon />}
          name={"deviationButton"}
          size={size}
          onClick={props.deviationClick}
          disabled={!props.enabled}
        >{t('deviation')}</Button>
      </Aux>
    );
}

export function ShipButton(props) {
    const { t } = useTranslation();
    let size = props.size !== undefined ? props.size : "small";
    let color = props.color !== undefined ? props.color : "secondary";
    return (
      <Aux>
        <Button
          variant="contained"
          color={color}
          sx={{ mx: 1 }}
          startIcon={<ShipIcon />}
          name={"shipButton"}
          size={size}
          onClick={props.shipClick}
          disabled={!props.enabled}
        >{t('ship')}</Button>
      </Aux>
    );
}

export function CompleteButton(props){
  let size = props.size !== undefined ? props.size : "small";
  let color = props.color !== undefined ? props.color : "secondary";
  return (
    <Aux>
      <Button
        variant="contained"
        color={color}
        startIcon={<CompleteIcon />}
        name="complete"
        disabled={!props.enabled}
        onClick={props.completeClick}
        size={size}
        fullWidth={props.fullWidth}
      >{props.buttonName}</Button>
    </Aux>
  );
};

export function AddActivityButton(props){
  const { t } = useTranslation();
  let size = props.size !== undefined ? props.size : "small";
  let color = props.color !== undefined ? props.color : "secondary";
  return (
    <Aux>
      <Button
        variant="contained"
        color={color}
        sx={{ m: 1 }}
        startIcon={<AddActivityIcon />}
        name="addActivity"
        disabled={!props.enabled}
        onClick={props.onClick}
        size={size}
        fullWidth={props.fullWidth}
      >{t('addActivity')}</Button>
    </Aux>
  );
};

export function UnshipButton(props) {
    const { t } = useTranslation();
    let size = props.size !== undefined ? props.size : "small";
    let color = props.color !== undefined ? props.color : "secondary";
    return (
      <Aux>
        <Button
          variant="contained"
          color={color}
          sx={{ mx: 1 }}
          name={"unshipButton"}
          size={size}
          onClick={props.unshipClick}
          disabled={!props.enabled}
        >{t('markSigned')}</Button>
      </Aux>
    );
};

export function ReceivedButton(props) {
    const { t } = useTranslation();
    let size = props.size !== undefined ? props.size : "small";
    let color = props.color !== undefined ? props.color : "secondary";
    return (
      <Aux>
        <Button
          variant="contained"
          color={color}
          sx={{ mx: 1 }}
          startIcon={<ReceivedIcon />}
          name={"receivedButton"}
          size={size}
          onClick={props.receivedClick}
          disabled={!props.enabled}
        >{t('received')}</Button>
      </Aux>
    );
};

export function UnreceiveButton(props) {
    const { t } = useTranslation();
    let size = props.size !== undefined ? props.size : "small";
    let color = props.color !== undefined ? props.color : "secondary";
    return (
      <Aux>
        <Button
          variant="contained"
          color={color}
          sx={{ mx: 1 }}
          name={"unreceiveButton"}
          size={size}
          onClick={props.unreceivedClick}
          disabled={!props.enabled}
        >{t('markSigned')}</Button>
      </Aux>
    );
};

export function UploadButton(props) {
  const { t } = useTranslation();
  const { uploadClick, enabled } = props;
  const size = props.size !== undefined ? props.size : "small";
  const color = props.color !== undefined ? props.color : "primary";
  const icon = <UploadIcon />;
  const text = t('upload');

  return (
    <Aux>
      <Button
        variant="contained"
        color={color}
        sx={{ mx: 1 }}
        endIcon={icon}
        onClick={uploadClick}
        disabled={!enabled}
        name={t('uploadButton')}
        size={size}
        key={'uploadButton'}
      >{text}</Button>
    </Aux>
  );
};