import React, { useEffect } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import Aux from '../../hoc/Auxilary/Auxilary';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import RichText from '../../hoc/RichText/RichText';
import common from '../../jsons/common.json';
import TablePagination from '@mui/material/TablePagination';
import Button from '../UI/Buttons/Button';

const Announcements = (props) => {
    const [list, setList] = React.useState([]);
    const [displayList, setDisplayList] = React.useState([]);
    const [announcement, setAnnouncement] = React.useState(null);
    const [totalCount, setTotalCount] = React.useState(0);
    const [listSize, setListSize] = React.useState(5);
    const [page, setPage] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation('translation');


    useEffect(() => {
        async function fetchData(){
            let sort = {'endDate': -1};
            let data = {sort: JSON.stringify(sort), type: props.type};
            let result = await axios.get('/api/announcementsdisplay', {params: data});
            let list = result.data.data;
            if(props.auth.user.daysUntilPassword != null && props.auth.user.daysUntilPassword <= 0){
                list.unshift({title: t('passwordExpired'), body: t('passwordExpired'), priority: common.announcementPriorities.high});

            } else if (props.auth.user.daysUntilPassword != null && props.auth.user.daysUntilPassword < 7){
                list.unshift({title: t('passwordExpiresIn') + props.auth.user.daysUntilPassword + t('daysLower'), body: t('passwordExpiresIn') + props.auth.user.daysUntilPassword + t('daysLower'), priority: common.announcementPriorities.medium});
            }
            if(props.auth.user.daysUntilPin != null && props.auth.user.daysUntilPin <= 0){
                list.unshift({title: t('pinExp'), body: t('pinExp'), priority: common.announcementPriorities.high});
            } else if (props.auth.user.daysUntilPin != null && props.auth.user.daysUntilPin < 7){
                list.unshift({title: t('pinExpiresIn') + props.auth.user.daysUntilPin + t('daysLower'), body: t('pinExpiresIn') + props.auth.user.daysUntilPin + t('daysLower'), priority: common.announcementPriorities.medium});
            }
            setList(list);
            setDisplayList(list.length > 0 ? list.slice(0, listSize) : []);
            setTotalCount(result.data.totalCount);
        }
        if(props.type != null && props.type !== ''){
            fetchData();
        }
    }, [props, listSize, t]);

    const rowClick = (i) => {
        setAnnouncement(list[i]);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        setAnnouncement(null);
    }

    const sliceList = (newPage) => {
        let newList = list.slice(listSize * newPage, listSize * (newPage + 1));
        setDisplayList(newList);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        sliceList(newPage);
    }

    const handleRowsPerPage = e => {
        setListSize(parseInt(e.target.value));
        sliceList(0);
    };
    

    return(
        <Aux>
            {list != null && list.length > 0 &&
                <Aux>
                    <SubSectionHeading title={t('announcements')} />
                    {displayList.map((row, i) => 
                        <Grid container spacing={1} key={row._id}>
                            <Grid item sm={10}>
                                <Button
                                    variant='text' 
                                    onClick={() => rowClick(i)}
                                    color={row.priority === common.announcementPriorities?.high ? 'error' : 'primary'}
                                    data-cy={'announcement' + i}
                                >{row.title}</Button>
                            </Grid>
                        </Grid>
                    )}
                    {totalCount > listSize &&
                        <TablePagination count={list.length}
                            rowsPerPage={listSize}
                            onPageChange={handleChangePage}
                            page = {page}
                            rowsPerPageOptions={common.rowOptions}
                            onRowsPerPageChange={handleRowsPerPage}
                        />
                    }
                </Aux>
            }
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
                <DialogTitle>
                    <SubSectionHeading title={announcement != null ? announcement.title : ''} />
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item sm={12}>
                            <RichText>{announcement != null ? announcement.body : ''}</RichText>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Aux>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(Announcements);