import React, { useEffect } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import Aux from '../../hoc/Auxilary/Auxilary';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import RichText from '../../hoc/RichText/RichText';
import Button from '../UI/Buttons/Button';

const WhatsNew = (props) => {
    const [content, setContent] = React.useState('');
    const {t} = useTranslation('translation');
    const uatURL = "https://support.evoratechnologies.com/support/solutions/articles/69000847824-what-s-new-in-uat-";
    const prodURL = "https://support.evoratechnologies.com/support/solutions/articles/69000848659-what-s-new-in-production-";

    useEffect(() => {
        async function fetchData(){
            let res = await axios.get('/api/whatsnew', {params: {documentId: props.documentId}});
            setContent(res.data);
        }
        if(props.open === true)
            fetchData();
    }, [props]);

    const openDocument = () => {
        if(props.prod === true){
            window.open(prodURL, '_blank');
        } else {
            window.open(uatURL, '_blank');
        }
    }

    return(
        <Aux>
            <Dialog open={props.open} onClose={props.handleClose} fullWidth maxWidth="md">
                <DialogTitle><SubSectionHeading title={content != null ? content.title : t('whatsNew')}/></DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Button 
                                variant='text'
                                onClick={() => openDocument()}
                                color='primary'
                                data-cy={'releaseNotesDoc'}
                            >
                                {t('openDocument')} 
                            </Button>
                        </Grid>
                    </Grid>
                    <br/>
                    <Grid container spacing={3}>
                        <Grid item sm={12}>
                            <RichText>{content != null ? content.description : ''}</RichText>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

        </Aux>
    )
}

export default WhatsNew;