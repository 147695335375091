import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle sx={{
        margin: 0,
        padding: 2,
        backgroundColor: 'secondary.main'
      }} {...other}>
      <Typography sx={{
        color: 'primary.main',
        fontFamily: 'Prompt',
        fontSize: '1.25em',
      }}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" sx={{
            position: 'absolute',
            right: 1,
            top: 1,
            color: '#FFF'
          }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

export default function Confirmation(props) {
  const { content, no, noButton, title, yes, yesButton, 
          toggleDialog, open, fullWidth } = props;

  return (
    <Dialog 
      open={open}  
      onClose={toggleDialog} 
      aria-labelledby="draggable-dialog-title" 
      PaperComponent={PaperComponent} 
      fullWidth={fullWidth}>
      <DialogTitle id="draggable-dialog-title" sx={{ cursor: 'move' }} onClose={toggleDialog} >
        {title}
      </DialogTitle>
      <DialogContent dividers sx={{ p: 2}}>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ m: 0, p: 2 }}>
        <Button onClick={no} color="secondary">
          {noButton}
        </Button>
        <Button onClick={yes} color="primary">
          {yesButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
}